import qs from 'qs';
import type { ParsedUrlQuery } from 'querystring';
import type { TireObject } from 'src/models';

import { isClientSide } from 'src/utils/EnvUtils';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sprintf = (format: string, ...args: string[]) => {
  let i = 0;
  return format.replace(/%s/g, function () {
    return args[i++];
  });
};

export const pluralString = (count: number, single: string, plural: string) =>
  isNaN(Number(count))
    ? null
    : count <= 1
    ? sprintf(single, count.toString())
    : sprintf(plural, count.toString());

const stringToHTML = (str: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return doc.body.innerHTML;
};

export const highlightSearchResults = (
  searchQuery: string,
  resultName: string,
) => {
  const substring = new RegExp(searchQuery, 'gi');

  const transformedString = resultName.replace(substring, (match) => {
    return `<mark>${match}</mark>`;
  });

  return stringToHTML(transformedString);
};

export const truncate = (sentence: string, amount: number, tail = '...') => {
  if (amount >= sentence.length) {
    return sentence;
  }

  const truncated = sentence.substring(0, amount);
  return `${truncated} ${tail}`;
};

export const randomString = (length: number) => {
  const result = [];
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength)),
    );
  }
  return result.join('');
};

export const garageTitleWording = (typeEnseigne: string) => {
  if (typeEnseigne.match(/carrosserie & garage/i)) {
    return 'garage et carrosserie';
  } else if (typeEnseigne.match(/carrosserie/i)) {
    return 'carrosserie';
  } else {
    return 'garage';
  }
};

export const tireDimensionsStringify = (tire: TireObject) => {
  return `${tire.width.label}/${tire.ratio.label} ${tire.rimDiameter.label} ${tire.load.label} ${tire.speed.label}`;
};

// decodeURIComponent(escape(...)) is used to handle non-Latin1 characters, such as "œ"
const atob = (str: string) => {
  return isClientSide()
    ? decodeURIComponent(escape(window.atob(str)))
    : Buffer.from(str, 'base64').toString();
};
// unescape(encodeURIComponent(...)) is used to handle non-Latin1 characters, such as "œ"
const btoa = (str: string) => {
  return isClientSide()
    ? window.btoa(unescape(encodeURIComponent(str)))
    : Buffer.from(str).toString('base64');
};

export const parseQueryStringHash = (
  routerQuery: ParsedUrlQuery,
  { keepCommas }: { keepCommas: boolean },
) => {
  return routerQuery.search && typeof routerQuery.search === 'string'
    ? qs.parse(atob(routerQuery.search), {
        arrayLimit: 300,
        comma: !keepCommas,
      })
    : undefined;
};

export const hashQueryParameters = (obj: Record<string, unknown>) => {
  return btoa(qs.stringify(obj, { arrayFormat: 'comma', encode: false }));
};
