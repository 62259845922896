import type { FunctionComponent, ParamHTMLAttributes } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import IconButton from '../Button/IconButton/IconButton';
import style from './ShowMoreText.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type ShowMoreTextProps = {
  heightLimit: number;
  opacity?: boolean;
  ellipsis?: boolean | string;
  buttonClassName?: string;
  seeMoreLabel?: string;
  seeLessLabel?: string;
  showMoreCallback?: (isToggled: boolean) => void;
  opacityClassName?: string;
  isContentClickable?: boolean;
} & ParamHTMLAttributes<unknown>;

const ShowMoreText: FunctionComponent<ShowMoreTextProps> = ({
  heightLimit,
  children,
  opacity,
  buttonClassName,
  ellipsis = false,
  showMoreCallback,
  seeMoreLabel = 'Voir plus',
  seeLessLabel = 'Voir moins',
  opacityClassName,
  isContentClickable = true,
  ...otherProps
}) => {
  const [isShownButtonMore, setIsShownButtonMore] = useState(false);
  const [showEffect, setShowEffect] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const handleToggle = () => {
    setIsToggled(!isToggled);
    setShowEffect(!showEffect);
  };

  useEffect(() => {
    setTimeout(() => {
      const descriptionHeight = descriptionRef.current?.offsetHeight ?? 0;
      if (descriptionHeight > heightLimit && !isShownButtonMore) {
        setIsShownButtonMore(true);
        setShowEffect(true);
      }
    });
  });

  const toggleIcon: { icon: string; label: string } = !isToggled
    ? { icon: 'fleche-b', label: seeMoreLabel }
    : { icon: 'fleche-h', label: seeLessLabel };

  return (
    <>
      <p
        onClick={
          isShownButtonMore && !ellipsis && isContentClickable
            ? () => {
                handleToggle();
                showMoreCallback?.(isToggled);
              }
            : () => undefined
        }
        {...otherProps}
      >
        <span
          ref={descriptionRef}
          style={{
            minHeight: heightLimit,
            height: showEffect ? heightLimit : 'initial',
          }}
          className={style.showMoreDescription}
        >
          {children}
          {ellipsis && <span>...</span>}
          {showEffect && opacity && (
            <span
              className={mergeClassNames([
                style.showMoreReadMore,
                opacityClassName,
              ])}
            />
          )}
        </span>
        {isShownButtonMore && !ellipsis && (
          <IconButton
            className={mergeClassNames([
              style.showMoreBtnReadMore,
              buttonClassName,
            ])}
            leftIcon={toggleIcon.icon}
            onClick={handleToggle}
            data-testid="show_more"
          >
            {toggleIcon.label}
          </IconButton>
        )}
      </p>
    </>
  );
};

export default ShowMoreText;
