import type { FunctionComponent } from 'react';
import React from 'react';

import { Svg } from 'src/components/shared';

import type { IconProps } from './types';

const Icon: FunctionComponent<IconProps> = ({
  color,
  width,
  height,
  name,
  className,
  onClick,
  dataTestid,
  shouldRandomizeClipPathId,
}) => {
  return (
    <Svg
      src={`/icons/components/Icon/${name}.svg`}
      color={color}
      height={width}
      width={height}
      className={className}
      onClick={onClick}
      data-testid={dataTestid}
      shouldRandomizeClipPathId={shouldRandomizeClipPathId}
    />
  );
};

export default Icon;
